var render = function render(_c, _vm) {
  return _c(
    "section",
    { class: [_vm.data.class, _vm.data.staticClass] },
    [
      _vm._t("title", function () {
        return [_vm._m(0)]
      }),
      _c(
        "div",
        { staticClass: "border border-dashed border-gray-400 text-center p-4" },
        [
          _vm._t("default", function () {
            return [
              _c("span", { staticClass: "text-gray-400" }, [_vm._v("PREVIEW")]),
            ]
          }),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function (_c, _vm) {
    return _c("header", [_c("h1", [_vm._v("Preview")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }