<template functional>
  <section :class="[data.class, data.staticClass]">
    <slot name="title">
      <header>
        <h1>Preview</h1>
      </header>
    </slot>
    <div class="border border-dashed border-gray-400 text-center p-4">
      <slot><span class="text-gray-400">PREVIEW</span></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CreativePreview',
};
</script>

<style scoped></style>
